<template>
	<div class="section-9 sect-mobile">
		<div class="container-section-mobile">
			<div class="title">2 Metode de Măsurare a progresului</div>
			<div class="desc">Obiectivele fără termene limită și metode clare de măsurare sunt visuri deșarte.</div>

			<div class="svg-animated">
				<svg-1-section-9 />
			</div>
		</div>
	</div>
</template>

<script>
	import Svg1Section9 from '../../assets/landing/mobile/Svg01Section09'

	export default {
		data() {
			return {

			}
		},
		components: {
			Svg1Section9
		},
		mounted(){
		}
	}
</script>